import React from "react";

// default layout
import Default from "../layouts/dashboard/default";

// Dashboard

import AlternateDashboard from "../views/dashboard/alternate-dashboard";

// icon pages
import Solid from "../views/dashboard/icons/solid";
import Outline from "../views/dashboard/icons/outline";
import DualTone from "../views/dashboard/icons/dual-tone";

// special pages
import Billing from "../views/dashboard/special-pages/billing";
import Calender from "../views/dashboard/special-pages/calender";
import Kanban from "../views/dashboard/special-pages/kanban";
import Pricing from "../views/dashboard/special-pages/pricing";
import Timeline from "../views/dashboard/special-pages/timeline";

// user pages

// extra
import Admin from "../views/dashboard/admin/admin";
import Uicolor from "../views/modules/plugins/pages/ui-color";

// widget
import Widgetbasic from "../views/dashboard/widget/widgetbasic";
import Widgetchart from "../views/dashboard/widget/widgetchart";
import Widgetcard from "../views/dashboard/widget/widgetcard";

// map
import Google from "../views/dashboard/maps/google";

// form
import FormElement from "../views/dashboard/from/form-element";
import FormWizard from "../views/dashboard/from/form-wizard";
import FormValidation from "../views/dashboard/from/form-validation";

// table
import BootstrapTable from "../views/dashboard/table/bootstrap-table";
import TableData from "../views/dashboard/table/table-data";
import Borderedtable from "../views/dashboard/table/border-table";
import Fancytable from "../views/dashboard/table/fancy-table";
import FixedTable from "../views/dashboard/table/fixed-table";

//extra
import PrivacyPolicy from "../views/dashboard/extra/privacy-policy";
import TermsofService from "../views/dashboard/extra/terms-of-service";
import BlankPage from "../views/dashboard/extra/blank-page";
import ShimmerComponent from "../views/dashboard/extra/ShimmerComponent";

import BlankPage1 from "../views/dashboard/extra/blank-page1";
import Users from "../views/modules/Event/User/Users";
import NewUserWizard from "../views/modules/Event/User/NewUserWizard";
import NewChekout from "../views/modules/Event/Events/LandingEvents/newCheckout";
import Bookings from "../views/modules/Event/Events/Bookings/Bookings";
import Userprofile from "../views/modules/e-commerce/pages/app/user-profile";
import ManageUser from "../views/modules/Event/User/ManageUser";
import PosBooking from "../views/modules/Event/POS/PosBooking2";
import AgentBooking from "../views/modules/Event/Agent/AgentBooking";
import Agent from "../views/modules/Event/Agent/Agent";
import Scanner from "../views/modules/Event/Scanner/Scanner";
import Camera from "../views/modules/Event/Scanner/Camera";
import POS from "../views/modules/Event/POS/pos";
import MailSetting from "../views/modules/Event/AdminSetting/MailSetting";
import PaymentGateway from "../views/modules/Event/AdminSetting/PaymentGateway/PaymentGateway";
import SmsSetting from "../views/modules/Event/AdminSetting/SmsSetting";
import AdminSetting from "../views/modules/Event/AdminSetting/AdminSetting";
import Events from "../views/modules/Event/Events/DashboardEvents/Events";
import MakeEvent from "../views/modules/Event/Events/DashboardEvents/MakeEvent";
import AddEvent from "../views/modules/Event/Events/DashboardEvents/AddEvents";
import Tax from "../views/modules/Event/Taxes/Tax";
import NewUser from "../views/modules/Event/User/NewUser";
import EditEvent from "../views/modules/Event/Events/DashboardEvents/EditEvent";
import Roles from "../views/modules/Event/Events/RolePermission/Roles";
import RolePermission from "../views/modules/Event/Events/RolePermission/RolePermission";
import AdminBookings from "../views/modules/Event/Events/Bookings/AdminBookings";
import ComplimentaryBookings from "../views/modules/Event/Complimentary/ComplimentaryBookings";
import CbList from "../views/modules/Event/Complimentary/CbList";
import Index from "../views/modules/Event/Dashboard";
export const DefaultRouter = [
  {
    path: "/",
    element: <Default />,
    // element: <Home />,
    children: [
      //custom 
      {
        path: "events/:id/process",
        element: <NewChekout />,
        name: 'process',
        active: 'process'
      },
      {
        path: "dashboard/",
        name: 'home',
        active: 'home',
        children: [
          {
            path: "",
            element: <Index />,
            name: 'dashboard',
            active: 'home'
          },
          {
            path: "alternate-dashboard",
            element: <AlternateDashboard />,
            name: 'alternate dashboard',
            active: 'home'
          },
          {
            path: "tax",
            element: <Tax />,
            name: 'alternate dashboard',
            active: 'home'
          },
          {
            path: "bookings-admin",
            element: <AdminBookings />,
            name: 'bookings-admin',
            active: 'home'
          },
          {
            path: "complimentary-bookings",
            element: <CbList/>,
            name: 'bookings-admin',
            active: 'home'
          },
          {
            path: "complimentary-bookings/new",
            element: <ComplimentaryBookings/>,
            name: 'bookings-admin',
            active: 'home'
          },
          {
            path: "events/",
            element: <Events />,
            name: 'alternate dashboard',
            active: 'events',
          },
          {
            path: "events/",
            active: 'events',
            children: [
              {
                path: "new",
                element: <MakeEvent />,
                name: 'new events',
                active: 'events'
              },
              {
                path: "edit/:id",
                element: <EditEvent />,
                name: 'edit events',
                active: 'events'
              },
              {
                path: "new/detail-info",
                element: <AddEvent />,
                name: 'new events',
                active: 'events'
              },
            ]
          },
          {
            path: 'bookings',
            element: <Bookings />,
            name: 'bookings'
          },
          {
            path: 'pos-bookings',
            element: <PosBooking />,
            name: 'Pos Bookings'
          },
          {
            path: 'agent-bookings',
            element: <AgentBooking />,
            name: 'agent-bookings'
          },
          {
            path: 'agent-bookings/new',
            element: <Agent />,
            name: 'agent-bookings'
          },
          {
            path: 'scan/',
            // element: <Scanner />,
            name: 'Scan',
            children: [
              {
                path: "scanner",
                element: <Scanner />,
                name: 'Scanner',
                subActive: 'scanner'
              },
              {
                path: "camera",
                element: <Camera />,
                name: 'Camera',
                subActive: 'camera'
              }
            ]
          },
          {
            path: 'pos',
            element: <POS />,
            name: 'POS'
          },
          {
            path: 'roles/',
            name: 'roles',
            children: [
              {
                path: "",
                element: <Roles />,
                name: 'roles',
                active: 'roles'
              },
              {
                path: "assign-permission/:id",
                element: <RolePermission />,
                name: 'Permission',
                active: 'Permission'
              },
            ]
          },
          {
            path: "users",
            element: <Users />,
            name: 'User List',
            active: 'pages',
            subActive: 'User'
          },
          {
            path: "users/manage/:id",
            element: <ManageUser />,
            name: 'User List',
            active: 'pages',
            subActive: 'User'
          },
          {
            path: "users/new",
            // element: <NewUserWizard />,
            element: <NewUser />,
            name: 'User List',
            active: 'pages',
            subActive: 'User'
          },
          {
            path: "settings/",
            active: 'settings',
            // element: <Setting />,
            children: [
              {
                path: "admin",
                element: <AdminSetting />,
                name: 'Admin',
                subActive: 'User'
              },
              {
                path: "mail",
                element: <MailSetting />,
                name: 'Mail',
                subActive: 'mail'
              },
              {
                path: "payment-gateway",
                element: <PaymentGateway />,
                name: 'Payment',
                subActive: 'Payment'
              },
              {
                path: "sms-gateway",
                element: <SmsSetting />,
                name: 'SMS',
                subActive: 'SMS'
              },
              {
                path: "otp",
                element: <AdminSetting />,
                name: 'OTP',
                subActive: 'User'
              },
              {
                path: "social-media",
                element: <AdminSetting />,
                name: 'Social Media',
                subActive: 'social-media'
              },
            ]

          },
        ]

      },
      // Special Pages
      {
        path: "dashboard/special-pages/billing",
        element: <Billing />,
        name: 'Billing',
        active: 'pages',
        subActive: 'SpecialPages'
      },
      {
        path: "dashboard/special-pages/calender",
        element: <Calender />,
        name: 'Calendar',
        active: 'pages',
        subActive: 'SpecialPages'



      },
      {
        path: "dashboard/special-pages/kanban",
        element: <Kanban />,
        name: 'Kanban',
        active: 'pages',
        subActive: 'SpecialPages'


      },
      {
        path: "dashboard/special-pages/pricing",
        element: <Pricing />,
        name: 'Pricing',
        active: 'pages',
        subActive: 'SpecialPages'

      },
      {
        path: "dashboard/special-pages/timeline",
        element: <Timeline />,
        name: 'Timeline',
        active: 'pages',
        subActive: 'SpecialPages'


      },
      //User
      {
        path: "dashboard/app/user-profile",
        element: <Userprofile />,
        name: 'User Profile',
        active: 'pages',
        subActive: 'User'
      },
      {
        path: "dashboard/app/user-add",
        element: <NewUserWizard />,
        name: 'User Add',
        active: 'pages',
        subActive: 'User'


      },
      {
        path: "dashboard/app/user-list",
        element: <Users />,
        name: 'User List',
        active: 'pages',
        subActive: 'User'
      },
      // Admin
      {
        path: "dashboard/admin/admin",
        element: <Admin />,
        name: 'Admin'

      },
      // Ui-Color
      {
        path: "plugins/ui-color",
        element: <Uicolor />,
        name: 'UI Color'
      },
      // Widgets
      {
        path: "dashboard/widget/widgetbasic",
        element: <Widgetbasic />,
        name: 'Basic Widget',
        active: 'elements',
        subActive: 'Widgets'

      },
      {
        path: "dashboard/widget/widgetchart",
        element: <Widgetchart />,
        name: 'Widget Chart',
        active: 'elements',
        subActive: 'Widgets'

      },
      {
        path: "dashboard/widget/widgetcard",
        element: <Widgetcard />,
        name: 'Widget Card',
        active: 'elements',
        subActive: 'Widgets'

      },
      // Map
      {
        path: "dashboard/map/google",
        element: <Google />,
        name: 'Google Map',
        active: 'elements',
        subActive: 'Map'

      },

      // Form
      {
        path: "dashboard/form/form-element",
        element: <FormElement />,
        name: 'Form Elements',
        active: 'elements',
        subActive: 'Form'

      },
      {
        path: "dashboard/form/form-wizard",
        element: <FormWizard />,
        name: 'Form Wizard',
        active: 'elements',
        subActive: 'Form'

      },
      {
        path: "dashboard/form/form-validation",
        element: <FormValidation />,
        name: 'Form Validation',
        active: 'elements',
        subActive: 'Form'


      },
      // Table
      {
        path: "dashboard/table/bootstrap-table",
        element: <BootstrapTable />,
        name: 'Bootstrap Table',
        active: 'elements',
        subActive: 'Table'


      },
      {
        path: "dashboard/table/table-data",
        element: <TableData />,
        name: 'Data Table',
        active: 'elements',
        subActive: 'Table'

      },
      {
        path: "dashboard/table/border-table",
        element: <Borderedtable />,
        name: 'Border Table',
        active: 'elements',
        subActive: 'Table'

      },
      {
        path: "dashboard/table/fancy-table",
        element: <Fancytable />,
        name: 'Fancy Table',
        active: 'elements',
        subActive: 'Table'

      },
      {
        path: "dashboard/table/fixed-table",
        element: <FixedTable />,
        name: 'Fixed Table',
        active: 'elements',
        subActive: 'Table'

      },

      // Icon
      {
        path: "dashboard/icon/solid",
        element: <Solid />,
        name: 'Solid Icons',
        active: 'elements',
        subActive: 'Icon'

      },
      {
        path: "dashboard/icon/outline",
        element: <Outline />,
        name: 'Outline Icons',
        active: 'elements',
        subActive: 'Icon'

      },
      {
        path: "dashboard/icon/dual-tone",
        element: <DualTone />,
        name: 'Dual Tone Icons',
        active: 'elements',
        subActive: 'Icon'

      },
      // extra-pages
      {
        path: "dashboard/extra/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "dashboard/extra/terms-of-service",
        element: <TermsofService />,
      },
      {
        path: "dashboard/blank-page",
        element: <BlankPage1 />,
        name: 'Blank-Page'
      },
      {
        path: "dashboard/extra/blank-page",
        element: <BlankPage />,


      },
      {
        path: "dashboard/extra/ShimmerComponent",
        element: <ShimmerComponent />,
      },
    ],
  },
];

import React, { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import axios from 'axios';
import { useMyContext } from '../../../../Context/MyContextProvider';
import partyImage from "../../../../assets/modules/e-commerce/images/product/party3.jpg";
import PosEventCard from './PosEventCard';
const PosEvents = ({ handleButtonClick }) => {
    const { api, authToken, UserData ,truncateString } = useMyContext();
    const [events, setEvents] = useState([]);
    useEffect(() => {
        axios.get(`${api}event-list/${UserData?.id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
                setEvents(res.data.events)
            }
        }).catch((err) =>
            console.log(err)
        )}, []);
    const formattedProductName = (name) => {
        let updated = name?.replace(' ', "-");
        return truncateString(updated)
    }
    const handleEventData = (id) => {
        handleButtonClick(id)
    }
    return (
        <Row className="row-cols-1">
            <div
                className="overflow-hidden slider-circle-btn"
                id="ecommerce-slider"
            >
                <Swiper
                    className="p-0 m-0 mb-2 swiper-wrapper list-inline text-center"
                    slidesPerView={6}
                    spaceBetween={50}
                    modules={[Navigation]}
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }}
                    breakpoints={{
                        320: { slidesPerView: 2 },
                        550: { slidesPerView: 2 },
                        991: { slidesPerView: 3 },
                        1400: { slidesPerView: 3 },
                        1500: { slidesPerView: 3 },
                        1920: { slidesPerView: 8 },
                        2040: { slidesPerView: 8 },
                        2440: { slidesPerView: 8 },
                    }}
                >
                    {events?.length > 0 && events?.map((item) =>
                        <SwiperSlide className="card-slide" onClick={() => handleEventData(item?.event_key)}>
                            <PosEventCard
                                productName={formattedProductName(item.name)}
                                productImage={item?.thumbnail ? item?.thumbnail : partyImage}
                                id={item?.event_key}
                                productRating="3.5"
                                statusColor="primary"
                            />
                        </SwiperSlide>
                    )}

                </Swiper>
                <div className="swiper-button swiper-button-next"></div>
                <div className="swiper-button swiper-button-prev"></div>
            </div>
        </Row>
    )
}

export default PosEvents
import React, { useState, useEffect, memo } from "react";

//react-bootstrap
import {
  Navbar,
  Container,
  Nav,
  Dropdown,
  Form,
  Image,
  Button,
  Collapse,
} from "react-bootstrap";

//router
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IndexRouters } from "../../../../router";

//component
import CustomToggle from "../../../dropdowns";
import Card from "../../../bootstrap/card";
import Modalpopup from "../../../../views/modules/auth/components/modal-popup";

//img
import img1 from "../../../../assets/images/shapes/01.png";
import img2 from "../../../../assets/images/shapes/02.png";
import img3 from "../../../../assets/images/shapes/03.png";
import img4 from "../../../../assets/images/shapes/04.png";

// logo
import Logo from "../../components/logo";

// Redux Selector / Action
import { useDispatch, useSelector } from "react-redux";

// Import selectors & action from setting store
import * as SettingSelector from "../../../../store/setting/selectors";


import RadioBtn from "../../../setting/elements/radio-btn";
import { logout } from "../../../../store/slices/authSlice";
import { useMyContext } from "../../../../Context/MyContextProvider";
import minilogo from '../../../../assets/event/stock/23.png'
import logo from '../../../../assets/event/stock/23.png'
const Headerpro = memo((props) => {
  const { UserData, isMobile, userRole } = useMyContext()
  const navbarHide = useSelector(SettingSelector.navbar_show); // array
  const themeFontSize = useSelector(SettingSelector.theme_font_size);
  const headerNavbar = useSelector(SettingSelector.header_navbar);
  useEffect(() => {

    if (headerNavbar === "navs-sticky" || headerNavbar === "nav-glass") {
      window.onscroll = () => {
        if (document.documentElement.scrollTop > 50) {
          document.getElementsByTagName("nav")[0].classList.add("menu-sticky");
        } else {
          document
            .getElementsByTagName("nav")[0]
            .classList.remove("menu-sticky");
        }
      };
    }

    document.getElementsByTagName("html")[0].classList.add(themeFontSize);
    //offcanvase code
    const result = window.matchMedia("(max-width: 1200px)");
    window.addEventListener("resize", () => {
      if (result.matches === true) {
        if (show1 === true) {
          document.documentElement.style.setProperty("overflow", "hidden");
        } else {
          document.documentElement.style.removeProperty("overflow");
        }
      } else {
        document.documentElement.style.removeProperty("overflow");
      }
    });
    if (window.innerWidth <= "1200") {

      if (show1 === true) {
        document.documentElement.style.setProperty("overflow", "hidden");
      } else {
        document.documentElement.style.removeProperty("overflow");
      }
    } else {
      document.documentElement.style.removeProperty("overflow");
    }
  });

  useEffect(() => {
    if (isMobile) {
      minisidebar()
    }
  }, []);
  const [show1, setShow1] = useState(false);

  const minisidebar = () => {
    const aside = document.getElementsByTagName("ASIDE")[0];
    if (aside) {
      aside.classList.toggle("sidebar-mini");
    } else {
      console.warn("Aside element not found");
    }
  };

  let location = useLocation();
  let history = useNavigate();

  // useEffect(() => {
  //   const currentValue = localStorage.getItem('mini');

  //   if (currentValue === 'true') {
  //     minisidebar()
  //   }
  // }, []);




  //optimized code 
  const route = IndexRouters && IndexRouters
    ?.slice(6)
    ?.flatMap(element => element.children.map(child => ({ element, child })))
    ?.find(({ element, child }) => {
      const path = element.path === "" ? `/${child.path}` : `/${element.path}/${child.path}`;
      return path === location.pathname;
    });
  const routeName = route ? route?.child.name : undefined;


  //active link
  const ActiveLink = route ? route?.child.active : undefined;
  const subActiveLink = route ? route?.child.subActive : undefined;

  //custom code
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = () => {
    dispatch(logout());
    navigate('/sign-in')
  }


  return (
    <Navbar
      expand="xl"
      className={`nav iq-navbar header-hover-menu left-border ${headerNavbar} ${navbarHide.join(
        " "
      )}`}
    >
      <Container fluid className="navbar-inner d-flex justify-content-between align-items-center" style={{ paddingRight: isMobile && '0' }}>
        {/* <Logo color={"true"} /> */}
        <div className="d-flex align-items-center">
          {/* {isMobile &&
          <Link to="/dashboard" className="navbar-brand">
            <Image src={logo} width={120}></Image>
          </Link>
        } */}
          {
            userRole !== 'User' &&
            <div
              // className="sidebar-toggle"
              // data-toggle="sidebar"
              data-active="true"
              onClick={minisidebar}
            >
              <Button className="bg-white"
              >
                <span className="navbar-toggler-icon ">
                  <span className=" bg-primary mt-1 navbar-toggler-bar bar1"></span>
                  <span className=" bg-primary navbar-toggler-bar bar2"></span>
                  <span className=" bg-primary navbar-toggler-bar bar3"></span>
                </span>
              </Button>
            </div>
          }
          <div className="d-flex align-items-center justify-content-between product-offcanvas">
            <div
              className={`offcanvas offcanvas-end shadow-none iq-product-menu-responsive ${show1 === true ? "show" : ""
                } `}
              tabIndex="-1"
              id="offcanvasBottom"
              style={{ visibility: `${show1 === true ? "visible" : "hidden"}` }}
            >
              <div className="offcanvas-body">
                {/* {!isMobile &&
                  <ul className="iq-nav-menu list-unstyled d-flex align-items-center">
                    <Image src={minilogo} width={200} height={25}></Image>
                  </ul>
                } */}
              </div>
            </div>
          </div>
        </div>
        {/* <h4 className="logo-title  ms-3 mb-0">Get Your Ticket</h4> */}
        {/* <div className="d-flex align-items-center">
          <Button
            id="navbar-toggle"
            bsPrefix="navbar-toggler"
            type="button"
            aria-expanded={show1}
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            onClick={() => setShow1(!show1)}
          >
            <span className="navbar-toggler-icon">
              <span className="mt-1 navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </span>
          </Button>
        </div> */}
        <div
          className={` navbar-collapse collapse show}`}
          // className={` navbar-collapse ${show1 === true ? "collapse show" : "collapse"
          id="navbarSupportedContent"
        >
        </div>
        <div className="mobile-actions d-flex align-items-center">
          <ul className="iq-nav-menu list-unstyled p-0 m-0">
            <Nav.Item as="li" className={`${ActiveLink === "/" ? "active" : ""}`}>
              <Nav.Link
                className="menu-arrow justify-content-start p-0"
                onClick={() => history('/')}
                aria-controls="homeData"
                // aria-expanded={open}
                role="button"
              >
                <div className="btn btn-primary btn-icon btn-sm rounded-pill">
                  <span className="btn-inner">
                    <svg
                      width="50"
                      className="icon-50"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.14373 20.7821V17.7152C9.14372 16.9381 9.77567 16.3067 10.5584 16.3018H13.4326C14.2189 16.3018 14.8563 16.9346 14.8563 17.7152V20.7732C14.8562 21.4473 15.404 21.9951 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0007C21.1356 20.3586 21.5 19.4868 21.5 18.5775V9.86585C21.5 9.13139 21.1721 8.43471 20.6046 7.9635L13.943 2.67427C12.7785 1.74912 11.1154 1.77901 9.98539 2.74538L3.46701 7.9635C2.87274 8.42082 2.51755 9.11956 2.5 9.86585V18.5686C2.5 20.4637 4.04738 22 5.95617 22H7.87229C8.19917 22.0023 8.51349 21.8751 8.74547 21.6464C8.97746 21.4178 9.10793 21.1067 9.10792 20.7821H9.14373Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
              </Nav.Link>
            </Nav.Item>
          </ul>
          {/* <ul className="iq-nav-menu list-unstyled p-0 m-0">
            <Dropdown as="li" className="nav-item">
              <Dropdown.Toggle
                as={CustomToggle}
                variant="py-0  d-flex align-items-center nav-link"
              >
                <div className="btn btn-primary btn-icon btn-sm rounded-pill">
                  <span className="btn-inner">
                    <svg
                      width="40"
                      className="icon-40"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z"
                        fill="currentColor"
                      ></path>
                      <path
                        opacity="0.4"
                        d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="end">
                <Dropdown.Item
                  as="button"
                  onClick={() => history("/dashboard/bookings")}
                >
                  My Bookings
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => history("/dashboard/app/user-profile")}
                >
                  Profile
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => history("/dashboard/app/user-privacy-setting")}
                >
                  Privacy Setting
                </Dropdown.Item>
                <hr className="dropdown-divider" />
                <Dropdown.Item onClick={() => handleLogOut()}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {
              !isMobile &&
              <li>{UserData?.name}</li>
            }
          </ul> */}
          <ul className="iq-nav-menu list-unstyled p-0 m-0 d-flex align-items-center">
            <Dropdown as="li" className="nav-item">
              <Dropdown.Toggle
                as={CustomToggle}
                variant="py-0  d-flex align-items-center nav-link"
              >
                <div className="btn btn-primary btn-icon btn-sm rounded-pill">
                  <span className="btn-inner">
                    <svg
                      width="40"
                      className="icon-40"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z"
                        fill="currentColor"
                      ></path>
                      <path
                        opacity="0.4"
                        d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="end">
                <Dropdown.Item as="button">
                  {UserData?.name}
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => history("/dashboard/bookings")}
                >
                  My Bookings
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => history("/dashboard/app/user-profile")}
                >
                  Profile
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => history("/dashboard/app/user-privacy-setting")}
                >
                  Privacy Setting
                </Dropdown.Item>
                <hr className="dropdown-divider" />
                <Dropdown.Item onClick={() => handleLogOut()}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </div>

      </Container>
    </Navbar>
  );
});

Headerpro.displayName = "Headerpro";
export default Headerpro;

import React, { useState, useEffect, memo, Fragment } from "react";
import {
  Row,
  Col,
  Table,
  Card,
  Tab,
  Nav,
} from "react-bootstrap";

import { Link } from "react-router-dom";

//apexcharts
import Chart from "react-apexcharts";


// Redux Selector / Action
import { useSelector } from "react-redux";
import Circularprogressbar from "../../../../components/circularprogressbar";
// Import selectors & action from setting store

import * as SettingSelector from "../../../../store/setting/selectors";
//Count-up
import CountUp from "react-countup";


// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "axios";
import { useMyContext } from "../../../../Context/MyContextProvider";

const Index = memo(() => {
  const { api, UserData, authToken } = useMyContext();
  useSelector(SettingSelector.theme_color);

  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };

  const variableColors = getVariableColor();
  const colors = [variableColors.primary, variableColors.info];

  useEffect(() => {
    return () => colors;
  });

  const chart1 = {
    options: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "28%",
          endingShape: "rounded-4",
          borderRadius: 3,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 3,
        colors: ["transparent"],
      },
      grid: {
        show: true,
        strokeDashArray: 7,
      },
      xaxis: {
        categories: ["S", "M", "T", "W", "T", "F", "S"],
        labels: {
          minHeight: 20,
          maxHeight: 20,
          style: {
            colors: "#8A92A6",
          },
        },
      },
      yaxis: {
        title: {
          text: "",
        },
        labels: {
          minWidth: 20,
          maxWidth: 20,
          style: {
            colors: "#8A92A6",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹ " + val + " thousands";
          },
        },
      },
      responsive: [
        {
          breakpoint: 1025,
          options: {
            chart: {
              height: 130,
            },
          },
        },
      ],
    },
    series: [
      {
        name: "Online Sale",
        data: [30, 50, 35, 60, 40, 60, 60],
      },
      {
        name: "Offline Sale",
        data: [40, 50, 55, 50, 30, 80, 30],
      },
    ],
  };
  const upArrow = (
    <svg className="" width="24" height="24px" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
      />
    </svg>
  );
  const downArrow = (
    <svg className="" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
      />
    </svg>
  );
 
  const [loading, setLoading] = useState(false);

  const [counts, setCounts] = useState([{
    offline : '',
    online : '',
    users : '',
    agents : '',
    pos : '',
    organizer : '',
    Scanner : ''
  }]);
  const [sale, setSale] = useState([{
    offline : '',
    online : '',
    agents : '',
    pos : '',
    organizer : '',
    Scanner : ''
  }]);
  const [cFees, setCFess] = useState([{
    offline : '',
    online : '',
    agents : '',
    pos : '',
    organizer : '',
    Scanner : ''
  }]);
  const getCounts = async () => {
    await axios.get(`${api}bookingCount/${UserData?.id}`, {
      headers: {
        'Authorization': 'Bearer ' + authToken,
      }
    }).then((res) => {
        if (res.data.status) {
          setCounts({
            offline: res.data.offlineBookings,
            online: res.data.onlineBookings,
            users: res.data.userCount,
            agents: res.data.agentCount,
            pos: res.data.posCount,
            organizer: res.data.organizerCount,
            scanner: res.data.scannerCount
          });
        }
      }).catch((err) => console.log(err));
  };
  const getSaleCounts = async () => {
    await axios.get(`${api}calculateSale/${UserData?.id}`, {
      headers: {
        'Authorization': 'Bearer ' + authToken,
      }
    }).then((res) => {
        if (res.data.status) {
          setSale({
            agentBooking: res.data.agentBooking,
            offline: res.data.offlineAmount,
            agents: res.data.agentBooking,
            online: res.data.onlineAmount,
            pos: res.data.posAmount,
          });
          setCFess({
            offline: res.data.offlineCNC,
            agents: res.data.agentCNC,
            online: res.data.onlineCNC,
            pos: res.data.posCNC,
          });
        }
      }).catch((err) => console.log(err));
  };

  useEffect(() => {
    getCounts()
    getSaleCounts()
  }, []);

  const growth = [
    {
      color: variableColors.info,
      value: 40,
      id: "circle-progress-06",
      svg: upArrow,
      name: "Online Bookings",
      start: 0,
      end: counts?.online,
      duration: 3,
    },
    {
      color: variableColors.info,
      value: 40,
      id: "circle-progress-06",
      svg: upArrow,
      name: "Offline Bookings",
      start: 0,
      end: counts?.offline,
      duration: 3,
    },
    {
      color: variableColors.primary,
      value: 91,
      id: "circle-progress-01",
      svg: upArrow,
      name: "Total Users",
      start: 0,
      end: counts?.users,
      duration: 3,
    },
    {
      color: variableColors.info,
      value: 80,
      id: "circle-progress-02",
      svg: downArrow,
      name: "Agents",
      start: 0,
      end: counts?.agents,
      duration: 3,
    },
    {
      color: variableColors.primary,
      value: 70,
      id: "circle-progress-03",
      svg: downArrow,
      name: "POS Users",
      start: 0,
      end: counts?.pos,
      duration: 3,
    },
    {
      color: variableColors.info,
      value: 60,
      id: "circle-progress-04",
      svg: upArrow,
      name: "Scanners",
      start: 0,
      end: counts?.scanner,
      duration: 3,
    },
    {
      color: variableColors.primary,
      value: 50,
      id: "circle-progress-05",
      svg: upArrow,
      name: "Organizer",
      start: 0,
      end: counts?.organizer,
      duration: 3,
    },
    
    // {
    //   color: variableColors.primary,
    //   value: 30,
    //   id: "circle-progress-07",
    //   svg: upArrow,
    //   name: "Members",
    //   start: 2,
    //   end: 11.2,
    //   duration: 3,
    //   suffix: "M",
    //   decimal: 1,
    // },
  ];
  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12}>
          <div className="overflow-hidden d-slider1">
            <Swiper
              as="ul"
              className="p-0 m-0 swiper-wrapper list-inline"
              slidesPerView={3.5}
              spaceBetween={32}
              autoplay={true}
              modules={[Navigation]}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              breakpoints={{
                320: { slidesPerView: 1 },
                550: { slidesPerView: 2 },
                991: { slidesPerView: 3 },
                1400: { slidesPerView: 4 },
                1500: { slidesPerView: 6 },
                1920: { slidesPerView: 6 },
                2040: { slidesPerView: 7 },
                2440: { slidesPerView: 8 },
              }}
              data-aos="fade-up"
              data-aos-delay="700"
            >
              {growth.map((item, index) => {
                return (
                  <SwiperSlide className="card card-slide" key={index}>
                    <Card.Body>
                      <div className="progress-widget mb-2">
                        <Circularprogressbar
                          stroke={item.color}
                          width="60px"
                          height="60px"
                          Linecap="rounded"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          style={{ width: 60, height: 60 }}
                          value={item.value}
                          id={item.id}
                        >
                          {item.svg}
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">{item.name}</p>
                          <h4 className="counter">

                            <CountUp
                              start={item.start}
                              end={item.end}
                              duration={item.duration}
                              separator=""
                              decimals={item.decimal}
                            />
                            {/* {item.suffix ? item.suffix : "K"} */}
                          </h4>
                        </div>
                      </div>
                    </Card.Body>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="swiper-button swiper-button-next"></div>
            <div className="swiper-button swiper-button-prev"></div>
          </div>
        </Col>
        {/* //firsr row */}
        <Col lg="3" md="6">
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <div className="d-flex align-items-start justify-content-between mb-2">
                <p className="mb-0 text-dark">Total Sales</p>
                <Link
                  className="badge rounded-pill bg-primary-subtle"
                  to="#javascript:void(0);"
                >
                  View
                </Link>
              </div>
              <div className="mb-3">
                <h2 className="counter">
                  ₹
                  <CountUp
                    start={0}
                    end={sale?.offline + sale?.online}
                    duration={10}
                    useEasing={true}
                    separator=","
                  />
                </h2>
                <small>Last updated 1 hour ago.</small>
              </div>
              <div>
                <Chart
                  options={chart1.options}
                  series={chart1.series}
                  type="bar"
                  height="100%"
                ></Chart>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="9" md="6">
          <Row>
            <Col lg="3" md="6">
              <Row>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark ">Online</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                      ₹
                        <CountUp
                          start={0}
                          end={sale?.online}
                          duration={10}
                          useEasing={true}
                          separator=""
                        />
                      </h2>
                      <small>Available to pay out.</small>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark">Offline</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                      ₹
                        <CountUp
                          start={0}
                          end={sale?.offline}
                          duration={5}
                          useEasing={true}
                        />
                      </h2>
                      <small>Transactions this month</small>
                    </Card.Body>
                  </Card>
                </div>
              </Row>
            </Col>
            <Col lg="3" md="6">
              <Row>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark ">EaseBuzz</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                        ₹
                        <CountUp
                          start={0}
                          end={sale?.online}
                          duration={10}
                          useEasing={true}
                          separator=""
                        />
                      </h2>
                      <small>Available to pay out.</small>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark">Agent Sales</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                        ₹
                        <CountUp
                          start={0}
                          end={sale?.agents}
                          duration={10}
                          useEasing={true}
                        />
                      </h2>
                      <small>Transactions this month</small>
                    </Card.Body>
                  </Card>
                </div>
              </Row>
            </Col>
            <Col lg="3" md="6">
              <Row>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark ">Instamojo</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                        ₹
                        <CountUp
                          start={0}
                          end={0}
                          duration={10}
                          useEasing={true}
                          separator=""
                        />
                      </h2>
                      <small>Available to pay out.</small>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark">POS Sales</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                        ₹
                        <CountUp
                          start={0}
                          end={sale?.pos}
                          duration={10}
                          useEasing={true}
                        />
                      </h2>
                      <small>Transactions this month</small>
                    </Card.Body>
                  </Card>
                </div>
              </Row>
            </Col>
            <Col lg="3" md="6">
              <Row>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark ">PhonePe</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                        ₹
                        <CountUp
                          start={0}
                          end={0}
                          duration={10}
                          useEasing={true}
                          separator=""
                        />
                      </h2>
                      <small>Available to pay out.</small>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark">Organizer Sales</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                        <CountUp
                          start={0}
                          end={0}
                          duration={10}
                          useEasing={true}
                        />
                      </h2>
                      <small>Transactions this month</small>
                    </Card.Body>
                  </Card>
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
        {/* //second row */}
        <Col lg="3" md="6">
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <div className="d-flex align-items-start justify-content-between mb-2">
                <p className="mb-0 text-dark">Total Convenience Fee</p>
                <Link
                  className="badge rounded-pill bg-primary-subtle"
                  to="#javascript:void(0);"
                >
                  View
                </Link>
              </div>
              <div className="mb-3">
                <h2 className="counter">
                  ₹
                  <CountUp
                    start={0}
                    end={cFees?.offline + cFees?.online}
                    duration={10}
                    useEasing={true}
                    separator=","
                  />
                </h2>
                <small>Last updated 1 hour ago.</small>
              </div>
              <div>
                <Chart
                  options={chart1.options}
                  series={chart1.series}
                  type="bar"
                  height="100%"
                ></Chart>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="9" md="6">
          <Row>
            <Col lg="3" md="6">
              <Row>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark ">Online C Fees</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                      ₹
                        <CountUp
                          start={0}
                          end={cFees?.online}
                          duration={10}
                          useEasing={true}
                          separator=""
                        />
                      </h2>
                      <small>Available to pay out.</small>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark">Offline C Fees</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                      ₹
                        <CountUp
                          start={0}
                          end={cFees?.offline}
                          duration={5}
                          useEasing={true}
                        />
                      </h2>
                      <small>Transactions this month</small>
                    </Card.Body>
                  </Card>
                </div>
              </Row>
            </Col>
            <Col lg="3" md="6">
              <Row>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark ">EaseBuzz C Fees</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                        ₹
                        <CountUp
                          start={0}
                          end={cFees?.online}
                          duration={10}
                          useEasing={true}
                          separator=""
                        />
                      </h2>
                      <small>Available to pay out.</small>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark">Agent Sales C Fees</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                        ₹
                        <CountUp
                          start={0}
                          end={cFees?.agents}
                          duration={10}
                          useEasing={true}
                        />
                      </h2>
                      <small>Transactions this month</small>
                    </Card.Body>
                  </Card>
                </div>
              </Row>
            </Col>
            <Col lg="3" md="6">
              <Row>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark ">Instamojo C Fees</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                        ₹
                        <CountUp
                          start={0}
                          end={0}
                          duration={10}
                          useEasing={true}
                          separator=""
                        />
                      </h2>
                      <small>Available to pay out.</small>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark">POS Sales C Fees</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                        ₹
                        <CountUp
                          start={0}
                          end={cFees?.pos}
                          duration={10}
                          useEasing={true}
                        />
                      </h2>
                      <small>Transactions this month</small>
                    </Card.Body>
                  </Card>
                </div>
              </Row>
            </Col>
            <Col lg="3" md="6">
              <Row>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark ">PhonePe C Fees</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                        ₹
                        <CountUp
                          start={0}
                          end={0}
                          duration={10}
                          useEasing={true}
                          separator=""
                        />
                      </h2>
                      <small>Available to pay out.</small>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-12">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark">Organizer Sales C Fees</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                        <CountUp
                          start={0}
                          end={0}
                          duration={10}
                          useEasing={true}
                        />
                      </h2>
                      <small>Transactions this month</small>
                    </Card.Body>
                  </Card>
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
        {/* third row */}
        <Col lg="6" md="12">
          <Card className="card-block">
            <Card.Header>
              <h4 className="text-center">
                Online Sale Overview
              </h4>
            </Card.Header>
            <Tab.Container defaultActiveKey="first">
              <div className="tab-bottom-bordered">
                <Nav
                  className="mb-0 nav nav-tabs justify-content-around"
                  id="nav-tab1"
                  role="tablist"
                >
                  <Nav.Link
                    className="py-3 "
                    eventKey="first"
                    data-bs-toggle="tab"
                    data-bs-target="#payment"
                    type="button"
                    role="tab"
                    aria-controls="payment"
                    aria-selected="true"
                  >
                    Highly Sales Event
                  </Nav.Link>
                  <Nav.Link
                    className="py-3"
                    eventKey="second"
                    data-bs-toggle="tab"
                    data-bs-target="#set"
                    type="button"
                    role="tab"
                    aria-controls="set"
                    aria-selected="false"
                  >
                    Low Sales Event
                  </Nav.Link>
                </Nav>
              </div>
              <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                <Tab.Pane
                  eventKey="first"
                  id="payment"
                  role="tabpanel"
                  aria-labelledby="payment"
                >
                  <div className="table-responsive">
                    <table
                      id="basic-table"
                      className="table mb-0 table-striped"
                      role="grid"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,833</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_vxnnjigakm</td>
                          <td className="text-dark">1 Hour Ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success ">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,204</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_uwsxaiuhhs</td>
                          <td className="text-dark">23 Days Ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,833</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_taxrcfzhny</td>
                          <td className="text-dark">1 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,235</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_pknfotsmhl</td>
                          <td className="text-dark">1 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,442</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_xqgczqbgto</td>
                          <td className="text-dark">3 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,924</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_eoasrkizdw</td>
                          <td className="text-dark">4 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  id="set"
                  role="tabpanel"
                  aria-labelledby="set"
                >
                  <div className="table-responsive">
                    <Table
                      id="basic-table"
                      className="table mb-0 table-striped"
                      role="grid"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,298</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_ufsoishqbw</td>
                          <td className="text-dark">7 Days Ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success ">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,032</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_fescijfgbb</td>
                          <td className="text-dark">23 Days </td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,514</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_eihghndltk</td>
                          <td className="text-dark">1 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,425</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_bvihnfpdfq</td>
                          <td className="text-dark">2 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,838</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_afrtmvdyjp</td>
                          <td className="text-dark">2 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,613</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_jterqcvjxz</td>
                          <td className="text-dark">5 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            <div className="card-footer text-end card-stretch card-height">
              <Link to="#javascript:void(0);">
                <span className="me-2">View all Settlements</span>{" "}
                <svg
                  width="20"
                  className="icon-20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 5L15.5 12L8.5 19"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </Link>
            </div>
          </Card>
        </Col>
        <Col lg="6" md="12">
          <Card className="card-block">
            <Card.Header>
              <h4 className="text-center">
                Offline Sale Overview
              </h4>
            </Card.Header>
            <Tab.Container defaultActiveKey="first">
              <div className="tab-bottom-bordered">
                <Nav
                  className="mb-0 nav nav-tabs justify-content-around"
                  id="nav-tab1"
                  role="tablist"
                >
                  <Nav.Link
                    className="py-3 "
                    eventKey="first"
                    data-bs-toggle="tab"
                    data-bs-target="#payment"
                    type="button"
                    role="tab"
                    aria-controls="payment"
                    aria-selected="true"
                  >
                    Highly Sales Event
                  </Nav.Link>
                  <Nav.Link
                    className="py-3"
                    eventKey="second"
                    data-bs-toggle="tab"
                    data-bs-target="#set"
                    type="button"
                    role="tab"
                    aria-controls="set"
                    aria-selected="false"
                  >
                    Low Sales Event
                  </Nav.Link>
                </Nav>
              </div>
              <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                <Tab.Pane
                  eventKey="first"
                  id="payment"
                  role="tabpanel"
                  aria-labelledby="payment"
                >
                  <div className="table-responsive">
                    <table
                      id="basic-table"
                      className="table mb-0 table-striped"
                      role="grid"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,833</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_vxnnjigakm</td>
                          <td className="text-dark">1 Hour Ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success ">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,204</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_uwsxaiuhhs</td>
                          <td className="text-dark">23 Days Ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,833</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_taxrcfzhny</td>
                          <td className="text-dark">1 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,235</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_pknfotsmhl</td>
                          <td className="text-dark">1 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,442</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_xqgczqbgto</td>
                          <td className="text-dark">3 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,924</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_eoasrkizdw</td>
                          <td className="text-dark">4 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  id="set"
                  role="tabpanel"
                  aria-labelledby="set"
                >
                  <div className="table-responsive">
                    <Table
                      id="basic-table"
                      className="table mb-0 table-striped"
                      role="grid"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,298</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_ufsoishqbw</td>
                          <td className="text-dark">7 Days Ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success ">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,032</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_fescijfgbb</td>
                          <td className="text-dark">23 Days </td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,514</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_eihghndltk</td>
                          <td className="text-dark">1 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,425</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_bvihnfpdfq</td>
                          <td className="text-dark">2 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,838</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_afrtmvdyjp</td>
                          <td className="text-dark">2 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,613</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_jterqcvjxz</td>
                          <td className="text-dark">5 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            <div className="card-footer text-end card-stretch card-height">
              <Link to="#javascript:void(0);">
                <span className="me-2">View all Settlements</span>{" "}
                <svg
                  width="20"
                  className="icon-20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 5L15.5 12L8.5 19"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

Index.displayName = "Index";
export default Index;

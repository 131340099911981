import React, { memo, Fragment, useEffect, useState } from "react";

// react-router
import { Outlet, useLocation } from "react-router-dom";

// headers
import Header1 from "../../../modules/landing-pages/components/partials/header/header-1";
import Header2 from "../../../modules/landing-pages/components/partials/header/header-2";

// footers
import Footer from "../../../modules/landing-pages/components/partials/footer/footer";
import Footer1 from "../../../modules/landing-pages/components/partials/footer/footer-one";

// scss
import { Button } from "react-bootstrap";

const DefaultLayout = memo((props) => {
  const [showBackToTop, setShowBackToTop] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      if (document.documentElement.scrollTop > 250) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    document.body.classList.add('landing-pages');
    document.documentElement.classList.add('landing-pages');
    document.body.classList.add('body-bg');

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.body.classList.remove('landing-pages');
      document.documentElement.classList.remove('landing-pages');
      document.body.classList.remove('body-bg');
    };
  }, []);
  const location = useLocation();
  const noMarginPaths = ['/', '/home'];
  const shouldApplyMargin = !noMarginPaths.includes(location.pathname);
  return (
    <Fragment>
      <main className="main-content">
        <div className="position-fixed w-100 top-0" style={{zIndex:'9999'}}>
          {/* <!--Nav Start--> */}
          {props.header1 === 'true' && <Header2 />}
          {props.header2 === 'true' && <Header2 />}
          {/* <!--Nav End--> */}
        </div>
        <div style={{ marginTop: shouldApplyMargin ? '4.8rem' : '0' }}>
            <Outlet />
        </div>
      </main>
      {/* <!--Nav Start--> */}
      {props.footer1 ? <Footer1 /> : <Footer />}
      {/* <!--Nav End--> */}

      {/* <a
        className="btn btn-primary btn-landing"
        href="/dashboard"
      >
        Dashboard Demo
      </a> */}
      {/* {{!-- Back-To-Top --}} */}
      {showBackToTop && (
        <div id="back-to-top">
          <Button size="xs" variant="secondary p-0 position-fixed top" href="#top">
            <svg
              className="icon-30"
              width="30"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 15.5L12 8.5L19 15.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </Button>
        </div>
      )}
      {/* {{!-- Back-To-end --}} */}
    </Fragment>
  );
});

DefaultLayout.displayName = "DefaultLayout";
export default DefaultLayout;

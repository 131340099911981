import React, { useEffect, useRef, useState } from "react";

// React-bootstrap
import {
    Row,
    Col,
    Image,
    Dropdown,
    Button,
    Table,
    Form,
    Tab,
    Nav,
    Card,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

// Router
import { Link } from "react-router-dom";

// Img
import img8 from "../../../../../assets/modules/e-commerce/images/user/06.png";

import { useMyContext } from "../../../../../Context/MyContextProvider";
import axios from "axios";
import html2canvas from "html2canvas";
import Ticket3 from "../Tickets/Ticket3";
import BookingList from "./BookingList";
const Bookings = () => {
    const { api, UserData,authToken } = useMyContext();
    const [bookings, setBookings] = useState([])
    
    


    const getBookings = async () => {
        await axios.get(`${api}bookings/${UserData?.id}`, {
            headers: {
              'Authorization': 'Bearer ' + authToken,
            }
          })
            .then((res) => {
                if (res.data.status) {
                    //console.log(res.data.bookings)
                    setBookings(res.data.bookings)
                }
            }).catch((err) =>
                console.log(err)
            )
    }

    useEffect(() => {
        getBookings()
    }, [])


   


    const ticketRefs = useRef([]);



    return (
        <>
            <Row>
                <Col lg="8">
                    <Card>
                        <Card.Header>
                            <h4 className="card-title">Booking History</h4>
                        </Card.Header>
                        <Card.Body>
                            <Tab.Container defaultActiveKey="first">
                                
                                <Tab.Content>
                                    <Tab.Pane
                                        eventKey="first"
                                        id="order"
                                        role="tabpanel"
                                        aria-labelledby="order-tab"
                                    >
                                       <BookingList bookings={bookings}/>
                                        <div className="col-12 text-center">
                                            <Link to="#" className="btn btn-primary">
                                                Load More
                                            </Link>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        eventKey="second"
                                        id="adress"
                                        role="tabpanel"
                                        aria-labelledby="adress-tab"
                                    >
                                        <Row>
                                            <div className="col-12">
                                                <div className="address-card">
                                                    <div className="border border-3 border-dashed p-2 mb-4">
                                                        <Link
                                                            to="#"
                                                            className="d-flex align-items-center justify-content-center"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                className="text-gray icon-20"
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M12.6035 27H17.3787V17.3787H27V12.6036H17.3787V3H12.6035V12.6036H3V17.3787H12.6035V27Z"
                                                                    fill="currentColor"
                                                                ></path>
                                                            </svg>
                                                            <h6 className="ms-2 mb-0">Add Address</h6>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        eventKey="thrid"
                                        id="card"
                                        role="tabpanel"
                                        aria-labelledby="card-tab"
                                    >
                                        <h4 className="mb-4">Your Payment Options</h4>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Col>
                
            </Row>
        </>
    )
}

export default Bookings

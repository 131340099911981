import React, {  Fragment, useState } from "react";

// React-bootstrap
import { Row, Col, Form, Button, Alert, Card } from "react-bootstrap";

// Router
import { Link, Navigate, useNavigate } from "react-router-dom";

//Img
import Autheffect from "../components/auth-effect";
import { useMyContext } from "../../../../../Context/MyContextProvider";
import {  useSelector } from "react-redux";
import axios from "axios";

//Component

const Signin = (() => {
  const { api } = useMyContext();
  let history = useNavigate();
  const [data, setData] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth?.user);
  const DataLength = (user && Object.keys(user)?.length) || 0;
  if (DataLength > 0) {
    return <Navigate to="/dashboard"/>
  }


  const handleLogin = async () => {
    try {
      setLoading(true)
      const response = await axios.post(`${api}verify-user`, { data })
      if (response.data.status) {
        history('/two-factor', { state: { data } })
        setLoading(false)
      } else {
        console.log(response)
        setLoading(false)
      }

    } catch (err) {
      setError(err.response.data.error)
      setLoading(false)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && data) {
      handleLogin();
    }
  };

  return (
    <Fragment>
     
      <div className="iq-auth-page">
        <Autheffect />
        <Row className="d-flex align-items-center iq-auth-container w-100">
          <Col xl="4" className="col-10 offset-xl-7 offset-1">
            <Card>
              <Card.Body>
                <h3 className="text-center">Sign In</h3>
                <p className="text-center h6">Sign in to stay connected</p>
                <div className="form-group">
                  {error && 
                  <Alert variant="danger d-flex align-items-center" role="alert">
                    <svg className="me-2" id="exclamation-triangle-fill" fill="currentColor" width="20" viewBox="0 0 16 16">
                      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                    </svg>
                    <div>
                      {error}
                    </div>
                  </Alert>
                  }
                  <input
                    type="email"
                    className="form-control mb-0"
                    id="email-id"
                    placeholder="Enter email or mobile number"
                    onChange={(e) => setData(e.target.value)}
                    onKeyDown={handleKeyDown}
                    autoFocus
                  />
                </div>
                {/* <div className="form-group">
                  <Form.Label htmlFor="password">Password</Form.Label>
                  <input
                    type="password"
                    className="form-control mb-0"
                    id="password"
                    placeholder="Enter password"
                  />
                </div> */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Form.Check className="form-check d-inline-block pt-1 mb-0 text-dark">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheck11"
                    />
                    <Form.Check.Label
                      htmlFor="customCheck11"
                    >
                      Remember Me
                    </Form.Check.Label>
                  </Form.Check>
                  <Link to="/auth/reset-password">Forgot password ?</Link>
                </div>
                <div className="text-center pb-3">
                  <Button onClick={() => handleLogin()} disabled={loading}>{loading ? 'Plase Wait...' : 'Sign in'}</Button>
                  {/* <Button onClick={() => setOtpState(true)}>Sign in</Button> */}
                </div>
                <p className="text-center h6">Or sign in with other accounts?</p>
                
                <p className="text-center h6">
                  Don't have account?
                  <Link to="/auth/sign-up" className="ms-2">
                    {" "}
                    Click here to sign up.
                  </Link>
                </p>
                <p className="text-center h6">
                  <Link to="/" className="ms-2">
                    {" "}
                    Go To Home
                  </Link>
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
});

Signin.displayName = "Signin";
export default Signin;
